$blue: #134379 !default;
$indigo: #6610f2 !default;
$purple: #663399 !default;
$pink: #e83e8c !default;
$red: #df0029 !default;
$orange: #e97d23 !default;
$yellow: #ffb822 !default;
$green: #4caf50 !default;
$teal: #31daa7 !default;
$cyan: #25acfd !default;
$alice-blue: #f0f8ff !default;
$vivid-cerulean: #00aff280 !default;
$kiwi: #75eb50 !default;
$kiwi-80-opacity: #75eb5080 !default;
$navy: #000080 !default;
$dark-cerulean: #134379 !default;

$primary-base: $blue;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black: darken($primary-base, 60%) !default;

$primary: $primary-base !default;
$primary-text: $primary-base !default;
$secondary: $teal !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-900 !default;
$background: $white !default;
$foreground: $gray-900 !default;
$heading: darken($primary-base, 8%);

$colors: (
	blue: $blue,
	indigo: $indigo,
	purple: $purple,
	pink: $pink,
	red: $red,
	orange: $orange,
	yellow: $yellow,
	green: $green,
	teal: $teal,
	cyan: $cyan,
	white: $white,
	gray: $gray-600,
	gray-dark: $gray-800,
) !default;

$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	gray-100: $gray-100,
	gray-200: $gray-200,
	gray-300: $gray-300,
	gray-400: $gray-400,
	dark: $dark,
) !default;

.main-color-logo {
	background: url(../../assets/images/favicon/android-chrome-512x512.png);
	background-repeat: no-repeat;
	background-size: contain;
	max-height: '512px';
	max-width: '512px';
}

.main-monocromatic-logo {
	background: url(../../assets/images/favicon/mHealthMonoCromatico.png);
	background-repeat: no-repeat;
	background-size: contain;
	max-height: '512px';
	max-width: '512px';
}

.gradient-background {
	background: $primary;
	background: linear-gradient(120deg, $primary 0%, $info 100%);
}

.header-cover {
	background: $primary;
	background: linear-gradient(120deg, $primary 0%, $info 100%);
	height: 12.5rem;
}

.welcome-container-cover {
	margin: -2rem -2rem 0;
	padding: 4rem 4rem 0;
	position: relative;
	transition: all 0.25s;
	animation: 0.25s;
	background: $primary;
	background: linear-gradient(120deg, $primary 0%, $info 100%);
}
