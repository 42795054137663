.avatar-sm {
	width: 36px;
	height: 36px;
}
.avatar-md {
	width: 54px;
	height: 54px;
}
.avatar-lg {
	width: 5rem;
	height: 5rem;
}
.avatar-xl {
	width: 20rem;
	height: 20rem;
}
