@import 'scss-config';
@import 'colors';
@import 'mixins/mixins';
@import 'palette';

@import 'animations';
@import 'typography';
@import 'height-width';
@import 'box-shadow';
@import 'layouts/layouts';
@import 'components/components';
@import 'views/views';

html {
	font-size: 16px;
}

body {
	letter-spacing: .3px;
	line-height: 1.6;
	background: $background;
	overflow-y: scroll;
	overflow-x: hidden;
}

[tabindex='-1']:focus {
	outline: none;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	height: 0;
}

select,
button,
textarea,
input {
	vertical-align: baseline;
}

div {
	box-sizing: border-box;
}

html,
body {

	&[dir=rtl],
	&[dir=ltr] {
		unicode-bidi: embed
	}
}

bdo[dir=rtl] {
	direction: rtl;
	unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
	direction: ltr;
	unicode-bidi: bidi-override;
}

img {
	max-width: 100%;
}

a,
a:focus,
a:hover {
	text-decoration: none;
}

blockquote {
	border-left: 2px solid $gray-200;
	padding-left: 1rem;
	margin-bottom: 1rem;
	font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	outline: none;
}

.o-hidden {
	overflow: hidden;
}

.o-auto {
	overflow: auto;
}

.separator-breadcrumb {
	margin-bottom: 2rem;
}

@for $i from 1 through 5 {
	.line-height-#{$i} {
		line-height: #{$i};
	}
}

.app-inro-circle {
	text-align: center;
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: calc(50% - 150px);

	.big-bubble {
		height: 280px;
		width: 280px;
		margin: 0 auto 20px;
		text-align: center;
		background: $primary;
		display: flex;
		align-items: center;
		justify-content: center;

		i {
			font-size: 108px;
			color: $white;
		}
	}
}

.loadscreen {
	text-align: center;
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	margin: auto;
	top: calc(50% - 100px);

	.logo {
		display: inline-block !important;
		width: 80px;
		height: 80px;
	}

}


@media (max-width: 576px) {
	.app-inro-circle {
		.big-bubble {
			width: 220px;
			height: 220px;
		}
	}
}

[dir="rtl"] {
	.rtl-ps-none {

		.ps__rail-x,
		.ps__rail-y {
			display: none;
		}
	}
}