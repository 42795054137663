@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~ngx-toastr/toastr.css';
@import '~angular-calendar/css/angular-calendar.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~highlight.js/styles/github.css';
@import '../iconsmind/iconsmind.css';
@import 'variables';
@import 'themes/themes';
@import '../vendor/bootstrap/bootstrap.scss';
// @import 'themes/lite-purple';
@import 'bootstrap-rtl.scss';
@import 'globals/globals';