@import 'scss-config';

body {
	overflow-y: auto;
	color: $primary-text;
}

body:not(.modal-open) {
	//https://stackoverflow.com/questions/25070144/bootstrap-modal-removes-scroll-bar
	//https://stackoverflow.com/questions/28643617/bootstrap-modal-makes-scrollbar-disappear-after-closing
	overflow: inherit !important;
}

.profileImgContainer {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: aliceblue;
	border-radius: 50%;
	border: 4px solid $white;
}

.thumbnail-column {
	max-width: 3.125rem !important;
	max-height: 3.125rem !important;
}

.cursor-auto {
	cursor: auto;
}

.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-wait {
	cursor: wait;
}

.cursor-text {
	cursor: text;
}

.cursor-move {
	cursor: move;
}

.cursor-help {
	cursor: help;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.modal {
	z-index: 1060;
}

.map-wrapper {
	width: 0;
	flex: 0;
	opacity: 0.1;
	transition: all 0.35s;
	animation: 0.5s;
}

.map-wrapper.show {
	width: 50%;
	opacity: 1;
	transition: all 0.35s;
	animation: 0.5s;
	flex: 50%;
}

.map-wrapper > div > div > app-map > div > google-map > div.map-container {
	min-width: 0 !important;
	width: 100% !important;
	max-width: 100% !important;
	opacity: 0.1;
	transition: all 0.35s;
	animation: 0.5s;
}

.map-wrapper.show > div > div > app-map > div > google-map > div.map-container {
	min-width: 0 !important;
	width: 100% !important;
	max-width: 100% !important;
	opacity: 1;
	transition: all 0.35s;
	animation: 0.5s;
}

.cal-month-view .cal-day-cell {
	min-height: 75px;
}

.user-profile {
	.user-info {
		margin-top: 0px;
	}
}

.messenger-container .bounce .sticky,
.minimized-container .bounce .sticky {
	animation: bounce 1s ease infinite;
}

@keyframes bounce {
	60% {
		transform: translateY(0%);
	}

	70% {
		transform: translateY(-20%);
	}

	80% {
		transform: translateY(0%);
	}

	90% {
		transform: translateY(-13%);
	}

	95% {
		transform: translateY(0%);
	}

	98% {
		transform: translateY(-7%);
	}

	100% {
		transform: translateY(0%);
	}
}

.img-bg-setup {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: aliceblue;
}

.agreement-obs .ql-container {
	min-height: 10rem;
	height: 10rem;
	max-height: 10rem;
}

.form-control-typeahead {
	max-height: 25rem;
	overflow-y: auto;
}

.map-container {
	width: 100% !important;
}

.account-modal > .modal-content {
	border: 0 !important;
	border-radius: 10px;
}

.attendance-modal > .modal-content {
	border: 0 !important;
	border-radius: 10px;
	background: transparent;
}

app-attendance-modal {
	position: absolute;
	width: 0;
	height: 0;
}

.ngb-dp-body {
	z-index: 1060 !important;
}

.visually-hidden {
	display: none;
}

@media (min-width: 1024px) {
	.auth-layout-wrap .auth-content {
		min-width: 900px;
	}
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1000px;
	}

	.auth-layout-wrap .auth-content {
		min-width: 1000px;
	}
}

@media (min-width: 1600px) {
	.modal-xxl {
		max-width: 1200px;
	}

	.auth-layout-wrap .auth-content {
		min-width: 1200px;
	}
}
